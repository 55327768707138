import _ from "lodash"
import cx from "classnames"
import noScroll from "no-scroll"
import React, { useEffect } from "react"

import { SpeakerBox } from "components"

import * as styles from "./styles.module.scss"

const TalkDetailsModal = ({ talk, close }) => {
  useEffect(() => {
    noScroll.on()

    return noScroll.off
  }, [])

  const isWorkshop = !_.isEmpty(talk.detailsUrl)

  return (
    <div className={styles.wrapper} onClick={close}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.close} onClick={close}>
          x
        </div>
        {_.isNil(talk.speaker) ? (
          <div className={styles.multipleSpeakersContainer}>
            {talk.speakers.map((speaker) => (
              <div className={styles.speakerContainer}>
                <SpeakerBox name={speaker.name} avatar={speaker.avatar} />
                <div className={styles.position}>{speaker.position}</div>
                <div className={styles.socialChannels}>
                  {speaker.socialChannels.map(({ icon, url }) => (
                    <a
                      href={url}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={styles.channel}>
                      <img src={icon} />
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <SpeakerBox
              nameOnBottom
              name={talk.speaker.name}
              avatar={talk.speaker.avatar}
            />
            <div className={styles.position}>{talk.speaker.position}</div>
            <div className={styles.socialChannels}>
              {talk.speaker.socialChannels.map(({ icon, url }) => (
                <a
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.channel}>
                  <img src={icon} />
                </a>
              ))}
            </div>
          </>
        )}
        <div>
          <h3 className={styles.sectionTitle}>About</h3>
          {_.isNil(talk.speaker) ? (
            talk.speakers.map(({ bio }) => <p className={styles.bio}>{bio}</p>)
          ) : (
            <p className={styles.bio}>{talk.speaker.bio}</p>
          )}

          <h3
            className={cx(styles.sectionTitle, styles.withAdditionalTopMargin)}>
            {isWorkshop ? "Workshop" : "Talk"}
          </h3>
          <h2 className={cx(styles.sectionTitle, styles.withReducedTopMargin)}>
            {talk.title}
          </h2>
          <p className={styles.bio}>{talk.description}</p>
          {!_.isEmpty(talk.requirements) && (
            <>
              <br />
              <h3
                className={cx(
                  styles.sectionTitle,
                  styles.withReducedTopMargin
                )}>
                Requirements
              </h3>
              <p className={styles.bio}>{talk.requirements}</p>
            </>
          )}

          {isWorkshop && (
            <a href={talk.detailsUrl} target="_blank" rel="noopener noreferrer">
              <button className={styles.learnMoreButton}>
                More details in the blog
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default TalkDetailsModal
