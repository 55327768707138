import React from "react"

export const POINTS = {
  conference: [
    {
      emoji: "🧭",
      text: (
        <>
          The <b>Adventurer</b> track will help all novice developers to learn
          and grow.
        </>
      ),
    },
    {
      emoji: "🧙",
      text: (
        <>
          The <b>Magician</b> track will feature the best content for all
          seasoned developers.
        </>
      ),
    },
    {
      emoji: "🦸",
      text: (
        <>
          The <b>Visioner</b> track will cover stories and ideas that will help
          you thrive in the software development industry.
        </>
      ),
    },
  ],
  workshops: [
    {
      emoji: "⚙️",
      text: "Don't miss the chance to gain practical knowledge!",
    },
    {
      emoji: "⚙️",
      text: "All workshops are for a limited number of participants.",
    },
    {
      emoji: "⚙️",
      text:
        "You can attend only one workshop by buying your ticket. The workshops tickets are separately sold by the conference day tickets.",
    },
    {
      emoji: "⚙️",
      text: (
        <>
          Full details about all workshops sessions, check out in our{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="/news/hackconf-2021-online-edition-workshops-announcement">
            blog post
          </a>
          .
        </>
      ),
    },
    {
      emoji: "⚙️",
      text: "The time slots are in EEST time zone.",
    },
  ],
}
