import experianLogo from 'images/sponsors/experian.png'
import halodxLogo from 'images/sponsors/halodx.png'
import progressLogo from 'images/sponsors/progress.png'
import reweLogo from 'images/sponsors/rewe.png'
import sapLogo from 'images/sponsors/sap.png'
import strypesLogo from 'images/sponsors/strypes.png'
import superhostingLogo from 'images/sponsors/superhosting.png'
import programistaLogo from 'images/sponsors/programista.png'
import nexoLogo from 'images/sponsors/nexo.png'
import milestoneLogo from 'images/sponsors/milestone.png'
import paysafeLogo from 'images/sponsors/paysafe.png'
import boschLogo from 'images/sponsors/bosch.png'
import uberLogo from 'images/sponsors/uber.png'
import chaosLogo from 'images/sponsors/chaos.png'
import vmwareLogo from 'images/sponsors/vmware.png'
import amadeusLogo from 'images/sponsors/amadeus.png'
import axwayLogo from 'images/sponsors/axway.png'
import dreamixLogo from 'images/sponsors/dreamix.png'
import softwareagLogo from 'images/sponsors/softwareag.png'
import launcheeLogo from 'images/sponsors/launchee.png'
import hypoportLogo from 'images/sponsors/hypoport.png'
import devrixLogo from 'images/sponsors/devrix.png'
import scalefocusLogo from 'images/sponsors/scalefocus.png'
import greenboneLogo from 'images/sponsors/greenbone.png'

export const GOOGLE_GLOBAL_SITE_TAG = `<!-- Global site tag (gtag.js) - Google Analytics -->
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-159914076-1');
`

export const MAILERLITE_SCRIPT = `(function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
_.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

               var ml_account = ml('accounts', '791671', 'v6b7v5g0j4', 'load');`

export const FACEBOOK_PIXEL_SCRIPT = `<!-- Facebook Pixel Code -->
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '509518335896450');
  fbq('track', 'PageView');

  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '118114680404370');
  fbq('track', 'PageView');
<!-- End Facebook Pixel Code -->`

export const LINKEDIN_PIXEL_SCRIPT = `(function(){var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})();`

export const GOLD_SPONSORS = [
  { url: 'https://www.experian.bg/', img: experianLogo },
  { url: 'https://www.rewe-digital.com/', img: reweLogo },
  { url: 'https://www.superhosting.bg/', img: superhostingLogo },
  { url: 'https://www.progress.com/', img: progressLogo },
  { url: 'https://strypes.eu/', img: strypesLogo },
  { url: 'https://www.sap.com/bulgaria/about.html', img: sapLogo },
  { url: 'https://bosch.io/about-us/locations/sofia/', img: boschLogo },
  { url: 'https://www.vmware.com/', img: vmwareLogo },
]

export const SILVER_PLUS_SPONSORS = [
  { url: 'https://www.halodx.com/', img: halodxLogo },
  { url: 'https://nexo.careers/', img: nexoLogo },
  { url: 'https://www.milestonesys.com/', img: milestoneLogo },
  {
    url: 'https://careers.paysafe.com/sofiaengineeringteams/',
    img: paysafeLogo,
  },
  {
    url: 'https://www.uber.com/us/en/careers/locations/sofia/',
    img: uberLogo,
  },
  {
    url: 'https://www.chaosgroup.com/',
    img: chaosLogo,
  },
  {
    url: 'https://amadeus.com/en',
    img: amadeusLogo,
  },
  { url: 'https://dreamix.eu/', img: dreamixLogo },
  { url: 'https://www.softwareag.com/', img: softwareagLogo },
  { url: 'https://www.launchee.space/', img: launcheeLogo },
  { url: 'https://devrix.com/', img: devrixLogo },
]

export const SILVER_SPONSORS = [
  { url: 'https://programista.bg/', img: programistaLogo },
  { url: 'https://www.axway.com/en', img: axwayLogo },
  { url: 'https://www.greenbone.net/en/jobs-bulgaria/', img: greenboneLogo },
  { url: 'https://www.scalefocus.com/', img: scalefocusLogo },
  { url: 'http://www.hypoport.bg/', img: hypoportLogo },
]
