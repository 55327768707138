import _ from 'lodash'

export const scrollToItem = ({ id, toggleMobileNavigation }) => {
  const element = document.getElementById(id)

  if (!element) {
    return
  }

  const elementPosition = element.getBoundingClientRect().top + window.scrollY

  const positionToScrollTo = elementPosition - 150 // 150 so that the navigation does not hide the element

  window.scrollTo({
    top: positionToScrollTo,
    behavior: 'smooth',
  })

  window.history.replaceState('', '', `/#${id}`)

  if (toggleMobileNavigation) {
    toggleMobileNavigation()
  }
}

export const handleInitialScroll = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '' // window is not present during build

  const path = _.last(url.split('/')) // get the id from url

  if (!path) {
    return
  }

  const id = _.last(_.split(path, '#')) // skip the #

  scrollToItem({ id })
}
