// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--Xm3ip";
export var container = "styles-module--container--QuFdc";
export var top = "styles-module--top--31yoF";
export var text = "styles-module--text---D_pF";
export var bottom = "styles-module--bottom--1Fa4W";
export var headline = "styles-module--headline--1iRW5";
export var pastEditions = "styles-module--pastEditions--cV2Z-";
export var videoCarousel = "styles-module--videoCarousel--3tL6p";
export var arrow = "styles-module--arrow--2X6dp";
export var videos = "styles-module--videos--s_pgr";
export var video = "styles-module--video--YX4V7";
export var dots = "styles-module--dots--3jxfz";
export var active = "styles-module--active--1eBUK";