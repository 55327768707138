import React from 'react'

import peopleIcon from 'images/people-icon.png'
import worldIcon from 'images/world-icon.png'
import thumbsUpIcon from 'images/thumbs-up-icon.png'

import * as styles from './styles.module.scss'

const PlatinumSponsors = () => (
  <div className={styles.container} id="sponsors">
    <div className={styles.title}>Sponsors</div>
    <div className={styles.description}>Stats from HackConf 2019</div>
    <div className={styles.points}>
      <div className={styles.point}>
        <img src={peopleIcon} alt="" />
        1300 attendees
      </div>
      <div className={styles.line} />
      <div className={styles.point}>
        <img src={worldIcon} alt="" />
        From 20 nationalities
      </div>
      <div className={styles.line} />
      <div className={styles.point}>
        <img src={thumbsUpIcon} alt="" />
        3500+ followers
      </div>
    </div>
  </div>
)

export default PlatinumSponsors
