// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--2E8hg";
export var fadeIn = "styles-module--fadeIn--3dTsj";
export var container = "styles-module--container--2_tuf";
export var close = "styles-module--close--2nd78";
export var multipleSpeakersContainer = "styles-module--multipleSpeakersContainer--CVRRr";
export var speakerContainer = "styles-module--speakerContainer---BiJZ";
export var position = "styles-module--position--lDoDI";
export var socialChannels = "styles-module--socialChannels--3IIu1";
export var channel = "styles-module--channel--3Qukq";
export var sectionTitle = "styles-module--sectionTitle--2C96W";
export var withAdditionalTopMargin = "styles-module--withAdditionalTopMargin--1NJQD";
export var withReducedTopMargin = "styles-module--withReducedTopMargin--L9DT0";
export var bio = "styles-module--bio--VFG3j";
export var learnMoreButton = "styles-module--learnMoreButton--2-RXO";