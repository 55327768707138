import _ from "lodash"
import React from "react"

import cx from "classnames"

import * as styles from "./styles.module.scss"

const SpeakerBox = ({ index, name, avatar, onClick, nameOnBottom }) => (
  <div
    onClick={onClick}
    className={cx(styles.container, {
      [styles.nameOnBottom]: nameOnBottom || index % 2 === 0,
      [styles.pointerCursor]: !_.isNil(onClick),
    })}>
    <img src={avatar} alt="speaker avatar" />
    <div className={styles.name}>{name}</div>
  </div>
)

export default SpeakerBox
