import React from 'react'

import cx from 'classnames'

import hc2021OnlineImage from 'images/blog/hc-2021-online.png'
import hc2021CFS from 'images/blog/cfs_new.png'
import hc2021Workshops from 'images/blog/workshop_announcement.jpg'
import hc2021SlidesPhotosVideos from 'images/blog/slides-photos-videos-hackconf-2021-online-edition.png'
import hackconfUpdate from 'images/blog/hackconf-update.png'

import * as styles from './styles.module.scss'

const NewsItem = ({ href, image, creationDate, title, description }) => (
  <div className={styles.newsItem} id="news">
    <a
      className={styles.left}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img height="139" width="210" src={image} alt="news item header" />
    </a>
    <div className={styles.right}>
      <div className={styles.creationDate}>{creationDate}</div>
      <a
        className={styles.title}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
)

const News = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={cx('section-title', styles.sectionTitle)}>News</div>
      <NewsItem
        href="/news/hackconf-update/"
        image={hackconfUpdate}
        creationDate="MAY. 20 2022"
        title="Important update by the HackConf team"
        description="It's almost the end of May, and there's no news about HackConf this year. 👀"
      />
      <NewsItem
        href="/news/slides-photos-videos-hackconf-2021-online-edition/"
        image={hc2021SlidesPhotosVideos}
        creationDate="NOV. 10 2021"
        title="Slides, photos and videos from HackConf 2021 Online edition"
        description="We want to thank everyone who attended online, supported, and participated in HackConf 2021!
Review your favorite talks or check out any you've missed."
      />
      <NewsItem
        href="/news/hackconf-2021-online-edition-workshops-announcement/"
        image={hc2021Workshops}
        creationDate="JUL. 27 2021"
        title="HackConf 2021 Online edition - Workshops announcement"
        description="You probably already know that this year's speakers and their talks are going to be something special. Now, keeping with tradition, let's see the workshop sessions!"
      />
      <NewsItem
        href="/news/hackconf-2021-online-call-for-speakers/"
        image={hc2021CFS}
        creationDate="APR. 14 2021"
        title="HackConf 2021 Online is looking for speakers"
        description="Come and share with us what you've been working on! We're looking for talk and workshop proposals! Apply before May 31."
      />
      <NewsItem
        href="/news/hackconf-2021-becomes-hackconf-2021-online/"
        image={hc2021OnlineImage}
        creationDate="MAR. 23 2021"
        title="HackConf 2021 becomes HackConf 2021 Online"
        description="We have important news to announce about HackConf 2021. Since we still have an ongoing pandemic, HackConf 2021 will be held online."
      />
    </div>
  </div>
)

export default News
