import React from 'react'

import hacksoftLogo from 'images/hacksoft-logo.png'
import hackbulgariaLogo from 'images/hackbulgaria-logo.png'

import cx from 'classnames'

import * as styles from './styles.module.scss'

const Footer = () => (
  <div className={styles.wrapper}>
    <div className={cx('container', styles.container)}>
      <div className={styles.left}>
        <a
          href="https://drive.google.com/file/d/1YTce-qmjSxroN5vK9jthXZCWthlr0ul-/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer">
          Code of conduct
        </a>
        <a
          href="https://drive.google.com/file/d/1Qb4FxMopWzLf5S38l9N7LMcr8zvAS7jv/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer">
          Terms and conditions
        </a>
        <a
          href="https://drive.google.com/file/d/1E1MePKkhsYiAQ-sDqZdxAADXkdF4p87-/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a
          href="https://drive.google.com/file/d/1b4SD9ycRuic10IzaZFg6wmiXpeKo2CC7/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer">
          Cookie Statement
        </a>
      </div>
      <div className={styles.right}>
        <div>
          Organised by
          <a
            href="https://hackbulgaria.com"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={hackbulgariaLogo}
              alt="hackbulgaria logo"
              className={styles.hackBulgariaLogo}
            />
          </a>
        </div>
        <div>
          Powered by
          <a
            href="https://hacksoft.io"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src={hacksoftLogo}
              alt="hacksoft logo"
              className={styles.hackSoftLogo}
            />
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
