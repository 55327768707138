import React from 'react'

import _ from 'lodash'
import cx from 'classnames'

import arrow from 'images/arrow.png'
import pastEditionsImage from 'images/past-editions-image.jpg'

import * as styles from './styles.module.scss'

const VIDEO_WIDTH = 306

const VIDEOS = [
  {
    title: 'HackConf 2021',
    url: 'https://www.youtube.com/watch?v=v1ioe-I80tM&list=PLmpSOHJJON0D0u7Cy_k68UqCgBWSKrieu',
    embedUrl: 'https://www.youtube.com/embed/v1ioe-I80tM',
  },
  {
    title: 'HackConf 2019',
    url: 'https://www.youtube.com/watch?list=PLmpSOHJJON0D1b02KwpwnELOQzE9M5iiz&v=769X9iMdvNI&feature=emb_title',
    embedUrl:
      'https://www.youtube.com/embed/videoseries?list=PLmpSOHJJON0D1b02KwpwnELOQzE9M5iiz',
  },
  {
    title: 'HackConf 2018',
    url: 'https://www.youtube.com/watch?list=PLmpSOHJJON0CHB7BqquS93Ng9on7up8nb&v=qoOmYiKTVJY',
    embedUrl:
      'https://www.youtube.com/embed/videoseries?list=PLmpSOHJJON0CHB7BqquS93Ng9on7up8nb',
  },
  {
    title: 'HackConf 2017',
    url: 'https://www.youtube.com/watch?list=PLmpSOHJJON0CGM_BTyHWjMKluegT6yke9&v=Ws0vs-_jVts&feature=emb_title',
    embedUrl:
      'https://www.youtube.com/embed/videoseries?list=PLmpSOHJJON0CGM_BTyHWjMKluegT6yke9',
  },
  {
    title: 'HackConf 2016',
    url: 'https://www.youtube.com/watch?list=PLmpSOHJJON0Bk-wXEcFFlro--SLvKOwsh&v=VBhHJCxoLeI',
    embedUrl:
      'https://www.youtube.com/embed/videoseries?list=PLmpSOHJJON0Bk-wXEcFFlro--SLvKOwsh',
  },
  {
    title: 'HackConf 2015',
    url: 'https://www.youtube.com/watch?list=PLmpSOHJJON0DuXjbVObCUTAOp7ZUtxa3d&v=3PmqawSIFpA&feature=emb_title',
    embedUrl:
      'https://www.youtube.com/embed/videoseries?list=PLmpSOHJJON0DuXjbVObCUTAOp7ZUtxa3d',
  },
]

class PastEditions extends React.Component {
  state = {
    activePoints: [0, 1, 2],
  }

  scrollVideos = ({ direction }) => {
    const scrollCoefficient = direction === 'left' ? 1 : -1

    this.videosContainer.scrollBy({
      left: scrollCoefficient * (VIDEO_WIDTH + 7), // additional 7px because of the margin between the videos
      behavior: 'smooth',
    })

    this.updateActivePoints({ direction })
  }

  updateActivePoints = ({ direction }) => {
    if (direction === 'left') {
      const newPoint = _.last(this.state.activePoints) + 1

      if (newPoint >= VIDEOS.length) {
        return
      }

      return this.setState((prevState) => ({
        activePoints: [...prevState.activePoints.slice(1, 3), newPoint],
      }))
    }

    const newPoint = _.head(this.state.activePoints) - 1

    if (newPoint < 0) {
      return
    }

    this.setState((prevState) => ({
      activePoints: [newPoint, ...prevState.activePoints.slice(0, 2)],
    }))
  }

  render() {
    return (
      <div className={styles.wrapper} id="past-events">
        <div className={cx('container', styles.container)}>
          <div className="section-title">Past editions</div>
          <div className={styles.top}>
            <div className={styles.text}>
              For the past 6 years, HackConf brought together thousands of
              developers from Bulgaria and all around the globe to spread the
              knowledge and celebrate the community together!{' '}
            </div>
            <img src={pastEditionsImage} alt="" />
            <div className={styles.text}>
              Each year we record all of the talks and make the videos available
              online, so everyone from anywhere can benefit from the insightful
              content our great speakers deliver.{' '}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.headline}>
              <div className={styles.pastEditions}>
                <a
                  href="https://2016.hackconf.bg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2016
                </a>
                <a
                  href="https://2017.hackconf.bg/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2017
                </a>
                <a
                  href="https://2018.hackconf.bg/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2018
                </a>
                <a
                  href="https://2019.hackconf.bg/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2019
                </a>
              </div>{' '}
              Enjoy the videos from our past editions:
            </div>
            <>
              <div className={styles.videoCarousel}>
                <img
                  alt=""
                  src={arrow}
                  className={styles.arrow}
                  onClick={() => this.scrollVideos({ direction: 'right' })}
                />
                <div
                  className={styles.videos}
                  ref={(element) => (this.videosContainer = element)}
                >
                  {VIDEOS.map(({ url, title, embedUrl }) => (
                    <div className={styles.video} key={url}>
                      <iframe
                        title="youtube-embed"
                        width={VIDEO_WIDTH}
                        height="168"
                        src={embedUrl}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      ></iframe>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {title}
                      </a>
                    </div>
                  ))}
                </div>
                <img
                  alt=""
                  src={arrow}
                  className={styles.arrow}
                  onClick={() => this.scrollVideos({ direction: 'left' })}
                />
              </div>
              <div className={styles.dots}>
                {_.range(VIDEOS.length).map((index) => (
                  <div
                    key={index}
                    className={cx({
                      [styles.active]: this.state.activePoints.includes(index),
                    })}
                  />
                ))}
              </div>
            </>
          </div>
        </div>
      </div>
    )
  }
}

export default PastEditions
