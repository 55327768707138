import React from 'react'

import cx from 'classnames'

import { NAV_ITEMS } from './constants'
import { scrollToItem, handleInitialScroll } from './utils'

import * as styles from './styles.module.scss'

const NavItem = ({
  content,
  active = false,
  to = '',
  link,
  className,
  toggleMobileNavigation,
}) =>
  link ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(styles.navItem, { [styles.active]: active }, className)}>
      {content}
    </a>
  ) : (
    <div
      className={cx(styles.navItem, { [styles.active]: active }, className)}
      onClick={() => scrollToItem({ id: to, toggleMobileNavigation })}>
      {content}
    </div>
  )

class Navigation extends React.Component {
  componentDidMount() {
    handleInitialScroll()
  }

  state = { showMobileNavigation: false }

  toggleMobileNavigation = () => {
    this.setState((prevState) => ({
      showMobileNavigation: !prevState.showMobileNavigation,
    }))
  }

  render() {
    const { showMobileNavigation } = this.state

    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <div className={cx('container', styles.container)}>
            {NAV_ITEMS.map((item) => (
              <NavItem {...item} className="remove-on-tablet" />
            ))}
            <div
              className={cx(styles.mobileNavigationTrigger, {
                [styles.isOpen]: showMobileNavigation,
              })}
              onClick={this.toggleMobileNavigation}>
              {showMobileNavigation ? (
                '✕'
              ) : (
                <React.Fragment>
                  <div />
                  <div />
                  <div />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div
          className={cx(styles.mobileNavigation, {
            [styles.isOpen]: showMobileNavigation,
          })}>
          {NAV_ITEMS.map((item) => (
            <NavItem
              {...item}
              toggleMobileNavigation={this.toggleMobileNavigation}
            />
          ))}
        </div>
      </React.Fragment>
    )
  }
}
export default Navigation
