import React from "react"

import cx from "classnames"
import { scrollToItem } from "components/Navigation/utils"

import * as styles from "./styles.module.scss"

const TICKET_PROPERTIES = [
  "Conference live stream access",
  "Ability to ask questions during the live stream",
  "Virtual Goodie Bag with valuable content & digital treats",
  "Special Physical Goodie Bag delivered to your home",
]

const WORKSHOP_TICKET_PROPERTIES = [
  "Every Workshop ticket allows you to take part in the desired workshop.",
  "All workshops are for a limited number of participants on a first-come, first-serve basis.",
  "You can attend only one workshop by buying your ticket.",
]

const Ticket = ({ wide, name, price, enabledProperties, additionalNote }) => (
  <div
    onClick={() => scrollToItem({ id: "ticketform" })}
    className={cx(styles.ticket, { [styles.workshopTicket]: wide })}>
    <div className={styles.name}>{name}</div>
    <div className={styles.price}>{price}</div>
    <div className={styles.properties}>
      {TICKET_PROPERTIES.map((property, index) => {
        const isActive = enabledProperties.includes(index)

        return (
          <div className={cx(styles.property, { [styles.active]: isActive })}>
            {property}
          </div>
        )
      })}
    </div>
    {additionalNote && (
      <div className={styles.additionalNote}>{additionalNote}</div>
    )}
  </div>
)

const WorkshopTicket = ({ name, price }) => (
  <div
    onClick={() => scrollToItem({ id: "ticketform" })}
    className={cx(styles.ticket, styles.workshopTicket)}>
    <div className={styles.name}>{name}</div>
    <div className={styles.price}>{price}</div>
    <div className={styles.properties}>
      {WORKSHOP_TICKET_PROPERTIES.map((property, index) => (
        <div key={index} className={cx(styles.property, styles.active)}>
          {property}
        </div>
      ))}
    </div>
    <div className={styles.additionalNote}>
      The workshops tickets are sold separately from the conference day tickets.
    </div>
  </div>
)

class Tickets extends React.Component {
  render() {
    return (
      <div className={styles.wrapper} id="tickets">
        <div className={styles.container}>
          <div className={cx("section-title", styles.sectionTitle)}>
            Tickets
          </div>
          <div className={cx("section-title", styles.title)}>
            Conference Day
          </div>
          <div className={styles.date}>
            22<sup>nd</sup> of October
          </div>
          <div className={cx(styles.description, styles.orange)}>
            Enjoy high-class software development talks and discussions from the
            comfort of your home.
          </div>

          <div className={styles.ticketTypes}>
            <Ticket
              name="Almost FREE Ticket"
              price="€1"
              enabledProperties={[0]}
            />
            <Ticket
              name="FULL ACCESS Ticket"
              price="€20"
              enabledProperties={[0, 1, 2]}
            />
            <Ticket
              name="SUPERFAN Ticket"
              price="€30"
              enabledProperties={[0, 1, 2, 3]}
              additionalNote="* Shipping is available only for
the territory of Bulgaria."
            />
          </div>

          <div className={styles.vatInfo}>
            * All prices include VAT.
            <br />
            <br />
            Each attendee will receive an e-mail with a personal ticket after
            the transaction is completed - please check your email. <br />
            <br />
            <b className={styles.orange}>⚠️ Note</b>: Conference tickets do not
            ​reserve a participation in a workshop. You'll have to buy a
            separate ticket for a workshop if you want to attend.
            <br />
            <br />
            If you have any problems with the payment process, please send us an
            e-mail to <a href="mailto:team@hackconf.bg">team@hackconf.bg</a> to
            assist you.
          </div>

          <div className={cx("section-title", styles.title)}>Workshops day</div>

          <div className={styles.date}>
            23<sup>rd</sup> of October
          </div>
          <div className={cx(styles.description, styles.orange)}>
            Gain new skills with our online hands-on, small-sized sessions by
            field experts.
          </div>
          <br />
          <WorkshopTicket
            name="Workshop ticket"
            price="€25"
            enabledProperties={[0]}
          />

          <script src="https://cdn.weemss.com/compiled/js/integration-embed.js?v1.7"></script>
          <i id="ticketform" />
          <iframe
            title="Ticket Purchase Form"
            src="https://event.gg/15282/form"
            id="weemss_integration_15282"
            frameBorder="0"
            width="100%"
            height="1050"></iframe>
        </div>
      </div>
    )
  }
}

export default Tickets
