import React from 'react'

import cx from 'classnames'
import { scrollToItem } from 'components/Navigation/utils'

import instagramIcon from 'images/insta_icon.png'
import facebookIcon from 'images/fb_icon.png'
import flickrIcon from 'images/flickr_icon.png'
import twitterIcon from 'images/twitter_icon.png'
import youtubeIcon from 'images/youtube_icon.png'
import hackconfLogo from 'images/hackconf_logo.png'
import linkedInIcon from 'images/linkedin_icon.png'

import * as styles from './styles.module.scss'

const Header = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <img src={hackconfLogo} alt="hackconf logo" />
      <div className={styles.slogan}>
        HackConf is on a vacation and will not return in 2022!
        <br />
        <br />
        Follow us on social channels or sign up for our newsletter not to miss
        when we're back.
      </div>
    </div>
    <div className={styles.socialsAndTalkSubmissionsWrapper}>
      <div
        className={cx('container', styles.socialsAndTalkSubmissionsContainer)}
      >
        <div className={styles.socials}>
          <a
            href="https://www.instagram.com/hackconf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="instagram icon" />
          </a>
          <a
            href="https://www.facebook.com/HackConference"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="facebook icon" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/hackconf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedInIcon} alt="linkedin icon" />
          </a>
          <a
            href="https://www.flickr.com/photos/159494454@N06/albums"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={flickrIcon} alt="flickr icon" />
          </a>
          <a
            href="https://twitter.com/HackConf_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="twitter icon" />
          </a>
          <a
            href="https://www.youtube.com/user/HackBulgaria"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtubeIcon} alt="youtube icon" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Header
