// extracted by mini-css-extract-plugin
export var ticket = "styles-module--ticket--3jASV";
export var workshopTicket = "styles-module--workshopTicket--2zKdv";
export var ticketDescription = "styles-module--ticketDescription--4CQuY";
export var orange = "styles-module--orange--1K2jC";
export var name = "styles-module--name--SwX4o";
export var price = "styles-module--price--xyhWK";
export var properties = "styles-module--properties--h06jG";
export var property = "styles-module--property--o9dW6";
export var active = "styles-module--active--3gkcj";
export var additionalNote = "styles-module--additionalNote--357Ou";
export var button = "styles-module--button--1BFgf";
export var wrapper = "styles-module--wrapper--5MS0L";
export var container = "styles-module--container--3lY2J";
export var sectionTitle = "styles-module--sectionTitle--1UYtg";
export var title = "styles-module--title--3sHpo";
export var date = "styles-module--date--1DhY_";
export var description = "styles-module--description--9NNCh";
export var ticketTypes = "styles-module--ticketTypes--39SiR";
export var vatInfo = "styles-module--vatInfo--3Rk2G";