import React, { useState } from 'react'

import Helmet from 'react-helmet'

import ogImage from 'images/og_image.png'

import {
  TalkDetailsModal,
  Navigation,
  Header,
  About,
  Speakers,
  Talks,
  Stats,
  SponsorUs,
  Footer,
  Partners,
  Location,
  Newsletter,
  MobileApp,
  PastEditions,
  Volunteers,
  News,
  Sponsors,
  Schedule,
  Workshops,
  OrganizingPartners,
} from 'components'

import {
  MAILERLITE_SCRIPT,
  GOOGLE_GLOBAL_SITE_TAG,
  FACEBOOK_PIXEL_SCRIPT,
  LINKEDIN_PIXEL_SCRIPT,
  GOLD_SPONSORS,
  SILVER_PLUS_SPONSORS,
  SILVER_SPONSORS,
} from './_constants'

import './base.scss'

import * as styles from './styles.module.scss'

const IndexPage = () => {
  const [talkForTalkDetailsModal, setTalkForTalkDetailsModal] = useState(null)

  return (
    <>
      <div className={styles.container}>
        <Helmet>
          <html lang="en" /> <title>HackConf</title>
          <meta
            name="description"
            content="HackConf is Bulgaria’s premier “for developers, by developers“ motivational and knowledge sharing event."
          />
          <meta
            name="facebook-domain-verification"
            content="hah30294se4dm57vwyx5j58ry3ih9v"
          />
          <meta property="og:image" content={ogImage} />
          <script>{GOOGLE_GLOBAL_SITE_TAG}</script>
          <script>{FACEBOOK_PIXEL_SCRIPT}</script>
          <script>{MAILERLITE_SCRIPT}</script>
          <script>{LINKEDIN_PIXEL_SCRIPT}</script>
          <meta
            name="google-site-verification"
            content="Ed0cwXVoFJDrGncm7HYlRlqLWB-BYcsjDMbb77dm8Uc"
          />
        </Helmet>

        <Navigation />
        <Header />
        <div className={styles.content}>
          <img
            height="1"
            width="1"
            alt=""
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=509518335896450&ev=PageView&noscript=1"
          />
          <iframe
            title="google-iframe"
            src="https://www.googletagmanager.com/ns.html?id=GTM-WDCFKB6"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
          <img
            height="1"
            width="1"
            alt=""
            style={{ display: 'none', visibility: 'hidden' }}
            src="https://px.ads.linkedin.com/collect/?pid=2077689&fmt=gif"
          />
          <About />
          {false && (
            <Schedule setTalkForTalkDetailsModal={setTalkForTalkDetailsModal} />
          )}
          {false && <Speakers />}
          {false && (
            <Talks setTalkForTalkDetailsModal={setTalkForTalkDetailsModal} />
          )}
          {false && (
            <Workshops
              setTalkForTalkDetailsModal={setTalkForTalkDetailsModal}
            />
          )}
          <Stats />
          {false && <SponsorUs />}
          <OrganizingPartners />
          {false && (
            <Sponsors
              withBottomDivider={false}
              type="Gold"
              sponsors={GOLD_SPONSORS}
            />
          )}
          {false && (
            <Sponsors
              withBottomDivider={false}
              type="Silver +"
              sponsors={SILVER_PLUS_SPONSORS}
            />
          )}
          {false && (
            <Sponsors
              withBottomDivider={true}
              type="Silver"
              sponsors={SILVER_SPONSORS}
            />
          )}

          {false && <Partners />}
          {false && <Location />}
          <Newsletter />
          {false && <MobileApp />}
          <PastEditions />
          {false && <Volunteers />}
          <News />
        </div>
        <Footer />
      </div>
      {talkForTalkDetailsModal && (
        <TalkDetailsModal
          talk={talkForTalkDetailsModal}
          close={() => setTalkForTalkDetailsModal(null)}
        />
      )}
    </>
  )
}

export default IndexPage
