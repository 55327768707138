import React from 'react'

import cx from 'classnames'

import * as styles from './styles.module.scss'

const About = () => (
  <div className={styles.wrapper} id="about">
    <div className={cx('container', styles.container)}>
      <div className="section-title">About</div>
      <div className={styles.description}>
        HackConf is Bulgaria's premier software development conference,
        organized "for developers, by developers".
      </div>
    </div>
  </div>
)

export default About
