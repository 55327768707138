import React from 'react'

import elenaGeorgieva from 'images/speakers/ElenaGeorgieva.jpg'
import radoslavStankov from 'images/speakers/RadoStankov.jpg'
import atanasKuzmanov from 'images/speakers/AtanasKuzmanov.jpg'
import georgiKostadinov from 'images/speakers/GeorgiKostadinov.jpg'
import yosifYosifov from 'images/speakers/YosifYosifov.jpg'
import darkoMeszaroc from 'images/speakers/DarkoMeszaroc.jpg'
import filaretaYordanova from 'images/speakers/FilaretaYordanova.jpg'
import ilianIliev from 'images/speakers/IlianIliev.jpg'
import bogdanaBoncheva from 'images/speakers/BogdanaBoncheva.jpg'
import subbyAngelov from 'images/speakers/SubbyAngelov.jpg'
import georgiStanev from 'images/speakers/GeorgiStanev.jpg'
import ivanVasilev from 'images/speakers/IvanVasilev.jpg'
import martinChaov from 'images/speakers/MartinChaov.jpg'
import ellMarquez from 'images/speakers/EllMarquez.jpg'
import nikolayAngelov from 'images/speakers/NikolayAngelov.jpg'
import nicoleFishbein from 'images/speakers/NicoleFishbein.jpg'
import zhivkoPetrov from 'images/speakers/ZhivkoPetrov.jpg'
import ivayloBachvarov from 'images/speakers/IvayloBachvarov.jpg'
import dmitryVinnik from 'images/speakers/DmitryVinnik.jpg'

import majidHajian from 'images/speakers/MajidHajian.jpg'
import bogomilShopov from 'images/speakers/BogomilShopov.jpg'
import georgiParlakov from 'images/speakers/GeorgiParlakov.jpg'
import antonSankov from 'images/speakers/AntonSankov.jpg'
import borisStoyanov from 'images/speakers/BorisStoyanov.jpg'
import danielRankov from 'images/speakers/DanielRankov.jpg'
import danailHadjiatanasov from 'images/speakers/DanailHadjiatanasov.jpg'
import hristinaBiserinska from 'images/speakers/HristinaBiserinska.jpg'
import preslavMihaylov from 'images/speakers/PreslavMihaylov.jpg'
import ivanIushkevich from 'images/speakers/IvanIushkevich.jpg'
import georgiDimitrov from 'images/speakers/GeorgiDimitrov.jpg'
import victorBotev from 'images/speakers/VictorBotev.jpg'
import davidLeitner from 'images/speakers/DavidLeitner.jpg'
import marianMarinov from 'images/speakers/MarianMarinov.jpg'
import maciejTreder from 'images/speakers/MaciejTreder.jpg'

import twitterIcon from 'images/twitter_icon.png'
import linkedinIcon from 'images/linkedin_icon.png'
import githubIcon from 'images/github_icon.png'
import linkIcon from 'images/link_icon.png'
import facebookIcon from 'images/fb_icon.png'

const BLOG_PATH = '/news/hackconf-2021-online-edition-workshops-announcement'

const SOCIAL_MEDIA_ICONS = {
  LINKEDIN: linkedinIcon,
  TWITTER: twitterIcon,
  GENERIC: linkIcon,
  GITHUB: githubIcon,
  FACEBOOK: facebookIcon,
}

export const TRACKS = {
  ADVENTURER: 'adventurer',
  MAGICIAN: 'magician',
  VISIONER: 'visioner',
}

export const TALKS = [
  {
    speaker: {
      name: 'Martin Chaov',
      avatar: martinChaov,
      position: 'Senior Software Architect @DraftKings',
      bio: (
        <>
          <i>Who are you?</i> - Martin Chaov
          <br />
          <i>Why do you want people to know about you?</i> - I don't want people
          to know about me. I want people to do good software.
          <br />
          <i>Who's your favorite member of One Direction?</i> - Is this
          something solved with Dijkstra shortest path algorithm?
          <br />
          <br />
          <br />I don't take myself too seriously. I am a tech enthusiast. Most
          of my sentient life I have tinkered with tech. In IT I've done almost
          every job imaginable. I was doing cables, PC assemblies, IT support,
          domain controllers, networks, ISP, graphic design, software
          development… And I am equally bad at everything, meaning I have a lot
          of hands-on experience to share :)
        </>
      ),
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/martin-chaov-79912910/ ',
        },
      ],
    },
    title: 'Performance 101',
    description: `Everything you know about software performance is probably wrong…

Performance is a business metric. Yes - how fast your website or service is loading/responding is a business requirement.

In this talk I am going to cover:
- What, why, and how should be measured?
- What are the possible root causes for poor performance?`,
    time: '14:00 - 15:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Ilian Iliev',
      avatar: ilianIliev,
      position: 'Platform Engineer @Lifesum, Sweden',
      bio: 'Programming since 2003, in love with Python since 2009. Huge fan of Python for its simplicity to use, power and flexibility. Working as a software engineer at Lifesum, a digital health company based in Stockholm, Sweden. Spends his free time snorkeling, fishing, traveling and with his cute baby girl. :)',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/ilian-iliev-6bb71620',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.FACEBOOK,
          url: 'https://www.facebook.com/IlianSIliev/',
        },
      ],
    },
    title: 'Lessons Learned The Hard Way',
    description:
      'We all have made mistakes in the past, from tiny bugs to crashing production for hours. To think that it will never happen again is a delusion, but analyzing the root cause of those errors can help us avoid them in the future.',
    time: '14:00 - 15:00',
    track: TRACKS.ADVENTURER,
  },
  {
    speaker: {
      name: 'Nikolay Angelov',
      avatar: nikolayAngelov,
      position: 'Head of Blockchain @ Nexo',
      bio: `Nikolay Angelov is a blockchain developer, public speaker and blogger. As a self-taught coder and enthusiast for all newly brewing tech, Nikolay, nicknamed Niki, began programming in 2007 when he wrote his first mIRC script.
He has since mastered multiple programming technologies and participated in creating blockchain solutions for property giant Emaar and numerous mainnet projects. At present, Niki is leading the Blockchain development team at Nexo – a leading digital finance institution.
He has lectured and led workshops at various blockchain conferences, reaching thousands of attendees each year, in addition to teaching blockchain courses in Singapore and the Philippines.
Niki hopes to introduce his own innovations to blockchain. While he figures out exactly what they will be, he is dedicating his free time to gaming and ruminating on different technical and blockchain-related topics via his blog.`,
      socialChannels: [],
    },
    title: 'Talk: Facing the Blockchain Challenges',
    description: `While Blockchains keep on getting popular and are awesome, there are still challenges that we need to solve before being able to handle mass adoption. In this talk we will dive into some of the Challenges that developers, protocols and services face while their business is related to Bitcoin and Ethereum and we will see some of the possible solutions for these challenges.
● How scalable are thеse networks?
● Are there privacy issues and should the day-to-day users be worried?
● Can we solve all the problems without risking the power of Decentralisation?`,
    time: '14:00 - 15:00',
    track: TRACKS.VISIONER,
  },
  {
    speaker: {
      name: 'Radoslav Stankov',
      avatar: radoslavStankov,
      position: 'Developer @Product Hunt',
      bio: 'Full stack developer with 19+ years of experience, currently he is head of engineering at Product Hunt. Enjoys blogging and solving problems.',
      socialChannels: [
        { icon: SOCIAL_MEDIA_ICONS.GENERIC, url: 'https://blog.rstankov.com/' },
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/rstankov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/rstankov/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/radoslavstankov/',
        },
        { icon: SOCIAL_MEDIA_ICONS.GENERIC, url: 'https://rstankov.com/' },
      ],
    },
    title: 'Eliminating noise in your code',
    description:
      'If you have to scan the code like an interpreter when you try to understand it -> it means it is too complicated. Tips and tricks how not to get into this situation.',
    time: '15:00 - 16:00',
    track: TRACKS.ADVENTURER,
  },
  {
    speaker: {
      name: 'Ivaylo Bachvarov',
      avatar: ivayloBachvarov,
      position: 'CTO @HackSoft',
      bio: `CTO at HackSoft, а co-founder of HackBulgaria, a passionate developer using Django and React. His background includes DevOps, teaching, and speaking at community events.

Ivaylo Bachvarov has been in the industry for more than 8 years and surely can share many diverse interests as IoT, automation, and leadership. Last but not least, he is an awesome father who likes to spent time with his lovely little girl.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/ivaylo-bachvarov/',
        },
      ],
    },
    title: 'End to End Automation from a Developer Standpoint',
    description: `In the talk I will cover:

- End to end automation for web based platforms.
- Where to start from?
- Difficulties and challenges.
- Best practices and things we learned the hard way.
- Automation and CI.
- Visual regression tests.
- How to do stress testing?`,
    time: '15:00 - 16:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Atanas Kuzmanov',
      avatar: atanasKuzmanov,
      position: 'Senior Software Engineer @Schwarz IT Bulgaria',
      bio: `A passionate software engineer with over 10 years of experience.

Studied and graduated at the University of Essex.

Lived for 10 years in the United Kingdom, 5 of which spent working for the BBC.

Has successfully led large-scale projects involving cross-domain collaboration over high performance critical applications with clients worldwide.

Constantly lead on a path of exciting challenges by an everlasting passion for technology.

Other interests include: Music, Travelling, Model cars, and more...`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/atkuzmanov/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/atkuzmanov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://atkuzmanov.wordpress.com/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/atkuzmanov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://linktr.ee/atkuzmanov',
        },
      ],
    },
    title: 'API-First! Contract-First! Make API development great again!',
    description:
      'For the very first time - AUTOMATIC OpenAPI ENDPOINT VALIDATION! These so-called OpenAPI documents can help you AUTOMATICALLY VALIDATE YOUR APIs and also serve as a contract, generate clients, servers, documentation, and much more…',
    time: '15:00 - 16:00',
    track: TRACKS.VISIONER,
  },
  {
    speaker: {
      name: 'Darko Meszaroс',
      avatar: darkoMeszaroc,
      position: 'Developer Advocate @AWS',
      bio: `Darko is a Senior Developer Advocate focusing on Central and Eastern Europe. He shares his passion and technological know-how with Engineers, Developers, and communities across the world. If it can be automated, Darko tries to do so. Most of his focus is towards DevOps and Management Tools, where automation, pipelines, and efficient developer tools is the name of the game – click less and code more so you do not repeat yourself - is what he says!

He has a long IT career in various areas, from retail, to government, to even large telcos. At AWS, Darko has worked as a Premium Support Engineer and as a Solutions Architect, which has given him first-hand experience with AWS customers and their AWS adventures, helping them learn the ways of the cloud!

As a passionate collector of old computers, he has a collection of computers and games and they came as an inspiration to tinker with computers as much as possible! Darko comes from Serbia, but has lived in Czech Republic, Malta, Ireland and now Germany!`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/darko-mesaros',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/darko-mesaro%C5%A1-02b66622/',
        },
      ],
    },
    title: 'For the love of PROD, Test your Code!',
    description: `That’s it! You hit that git push command! The code it’s on it’s way to production. It’s an amazing piece of code, after all - you wrote it. But are you sure its good? Are you sure it will not break anything down the line? Okay, let’s talk about code testing strategies on AWS.`,
    time: '16:00 - 17:00',
    track: TRACKS.ADVENTURER,
  },
  {
    speaker: {
      name: 'Filareta Yordanova',
      avatar: filaretaYordanova,
      position: ' Technical Architect @Experian',
      bio: `Her career has started in an outsourcing company, which helped her acquire broad technical skills. Moving to an internship in Google, followed by working in a small startup and larger enterprise companies.
She is curious to explore new technologies and programming paradigms. Filareta has diverse experience - from research projects, utilizing signal processing and ML models, to building event-driven architectures.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'http://www.linkedin.com/in/filareta-yordanova-a68aa795',
        },
      ],
    },
    title: 'Making Sense of Stream Processing',
    description:
      'Structuring data as a stream of events is nothing new. With the advent of open source projects such as Apache Kafka, Spark Streaming and Kafka Streams, stream processing is gaining popularity in software projects. This model can be used to represent nearly every business activity we care to analyze.',
    time: '16:00 - 17:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Bogdana Boncheva',
      avatar: bogdanaBoncheva,
      position: 'Agile Project Manager @Financial Times',
      bio: 'Bogdana Boncheva is an Agile Project Manager at heart and in reality. She is passionate about organising product delivery processes that are easy to follow and bringing the best out of people. She and her teams have started using OKRs 2 years ago and have been fearless ever since. Bogdana has lived in China and speaks Chinese, which she considers easier than any programming language.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/bogdana-boncheva-b090b0121/',
        },
      ],
    },
    title: 'Be Fearless: achieve your goals with OKRs',
    description:
      "Are you achieving your most important goals? Are your great ideas making it to the finish line? Objectives & Key Results is the goal setting practice that helps teams at the Financial Times turn ideas into products. Come and find out how you can use OKRs to enhance yours and your team's achievements.",
    time: '16:00 - 17:00',
    track: TRACKS.VISIONER,
  },
  {
    speaker: {
      name: 'Ivan Vasilev',
      avatar: ivanVasilev,
      position: 'Engineering Manager @Experian',
      bio: 'Having worked on software, electronics and communications in the past 20 years, Ivan is fascinated by all things technical. You can often find him developing a well-being application in the wee hours of the night, troubleshooting a three-phase, remotely controlled electrical motor ten meters above a pond the next day and containerizing 30-year old mainframe software by next week.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/ivanvasilev/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/zmeiresearch/',
        },
      ],
    },
    title: 'Why go RISC-V-y?',
    description: `You've interacted with several hundreds processors today already - from tiny ones in coffee machines to huge behemoths in data centers. They come in different forms and capabilities, but are all closed designs that you just use.
RISC-V changes that. Join me to understand how and why this is cool.`,
    time: '17:00 - 18:00',
    track: TRACKS.VISIONER,
  },
  {
    speaker: {
      name: 'Yosif Yosifov',
      avatar: yosifYosifov,
      position: 'Engineering Manager at OfficeRnD',
      bio: "Yosif is an engineering manager especially interested in building cloud products and distributed systems. He loves scaling teams, processes, and systems. Has a can-do attitude, but humble. He's a people person and would always take one for the team. Deep down he's an engineer at heart.",
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/yosifyosifov/',
        },

        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/yyosifov',
        },
      ],
    },
    title: 'The art of scaling an Engineering Organization',
    description: `How do you evolve an engineering organization’s growth from 3 to 30 people, and above? I’ll share how we tackled:

- Evolution of different engineering organization designs
- Doubling team size, but try to stay productive in hypergrowth
- Scale processes and structure, yet foster innovation`,
    time: '17:00 - 18:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Zhivko Petrov',
      avatar: zhivkoPetrov,
      position: 'Senior C++ Software Engineer @Ocado Technology',
      bio: `Zhivko is a C++ Software Engineer with 6+ years of professional experience.
Currently, he is working as a Robotics Software Engineer in the field of Perception Robotics.
The background project that he is most proud of is a 2D game engine.
His interests also include algorithms, code optimization, and game development.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/zhivko-petrov/',
        },
      ],
    },
    title: 'Batman and the algorithms',
    description: `Ever wondered how pathfinding is implemented in games? How about calculating the area of a complex shape with only using random numbers?
Join me and Batman as my sidekick where we explain the answers to those questions and many more using some cool demos.`,
    time: '17:00 - 18:00',
    track: TRACKS.ADVENTURER,
  },
  {
    speaker: {
      name: 'Georgi Kostadinov',
      avatar: georgiKostadinov,
      position: (
        <>
          Head of AI @Imagga
          <br />
          Co-founder & CTO @Kelvin Health
        </>
      ),
      bio: "Georgi, a Head of AI & CTO at Imagga and Kelvin Health, has more than 10 years of experience in research and development of various machine learning algorithms for image and video understanding and over 15 years of experience in developing, deploying and managing complex software architectures. He is the key person responsible for the development of Imagga's core AI technology, as well as fine-tuning its performance and specialization for a variety of practical use-cases.",
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/grgkostadinov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/georgi-kostadinov/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.FACEBOOK,
          url: 'https://www.facebook.com/grgkostadinov',
        },
      ],
    },
    title:
      'Visual Attention is All You Need: Introduction to Visual Transformers',
    description: 'TO BE ANNOUNCED',
    time: '18:00 - 19:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Georgi Stanev',
      avatar: georgiStanev,
      position: 'Head of Mobile Development @Prime Holding JSC',
      bio: 'High‐performing, strategic‐thinker with more than 12 years of software development experience in various programming languages and tools. Georgi is a technical leader and full-stack developer with strong expertise in Mobile Development iOS/Android/Flutter but also with great knowledge in Bаckend Development PHP/Java. He worked on many commercial projects in various business domains such as trading platforms, network monitoring systems, reporting systems and more.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/georgi-stanev-4214888b/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/StanevPrime ',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://medium.com/@georgi.stanev',
        },
      ],
    },
    title: 'Building scalable, maintainable and robust apps in Flutter',
    description:
      'State management is one of the hottest topics in the Flutter community. If you want the app you are building to be robust, scalable, and maintainable, you need to use a neat architecture. In this talk, you will learn how to achieve all of the above by using rx_bloc as a state management solution.',
    time: '18:00 - 19:00',
    track: TRACKS.ADVENTURER,
  },
  {
    speaker: {
      name: 'Subby Angelov',
      avatar: subbyAngelov,
      position: 'Team Lead @Nemetschek Bulgaria',
      bio: 'Some 20 years ago, Subby started as a developer. Now he leads 9 teams with more than 80 people. In his leadership practice, he had to solve many different cases involving many different people. The experience he gained is worth sharing. Subby believes that the best way you can do good to someone is by sharing what you know. He believes in shortcuts and speed lines. He is a passionate speaker and enthusiastic to help people achieve more.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/subbyangelov/',
        },
      ],
    },
    title: 'How to be a conscious leader?',
    description:
      'Leadership is a skill. It is not necessary to learn it the hard way. Knowing the answers to several important questions makes it a lot easier. Come to find out why some are better leaders then others? Practical examples of good leadership. And ask yourself whether you would be a good leader?',
    time: '18:00 - 19:00',
    track: TRACKS.VISIONER,
  },
  {
    speakers: [
      {
        name: 'Ell Marquez',
        avatar: ellMarquez,
        position: 'Linux and Security Advocate',
        bio: (
          <>
            <b>Ell Marquez</b>, advocate for Hacking Is Not A Crime and
            Operation Safe Escape has traveled the world for the last five
            years, educating security practitioners on subjects from on-prem
            infrastructure to the cloud and everything in between.
          </>
        ),
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.TWITTER,
            url: 'https://twitter.com/ell_o_punk ',
          },
        ],
      },
      {
        name: 'Nicole Fishbein',
        avatar: nicoleFishbein,
        position: 'Security Resarcher',
        bio: (
          <>
            <br />
            <b>Nicole Fishbein</b> has spent the last six years entrenched in
            the security world as part of the IDF and currently discovering new
            malware as a part of the Intezer research team. Nicole's research
            contributions have led to the discovery of previously undetected
            malware and attack techniques.
          </>
        ),
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.TWITTER,
            url: 'https://twitter.com/nicolefishi19',
          },
        ],
      },
    ],
    title: 'Missing Piece of Cloud Security',
    description:
      'With attacks to cloud environments in the first quarter of 2020 increasing 250%, prevention and detection have become the center focus for organizations. These concerns stem from an overwhelming amount of services and tools offered by cloud providers.',
    time: '19:00 - 20:00',
    track: TRACKS.MAGICIAN,
  },
  {
    speaker: {
      name: 'Elena Georgieva',
      avatar: elenaGeorgieva,
      position: 'Technical Director @Financial Times',
      bio: 'Elena Georgieva is currently Technical Director at the Financial Times. Before that, for the last 2 years, she has been leading the Data Platform Team based in Sofia, Bulgaria as a Principal Engineer. Elena is a software professional with a focus on Data Management and Applications with broad technology experience from relational databases to Big Data solutions with 20 years of experience in the industry. Last 6 years she has been specializing in platforms for telemetry storage and analysis of product usage.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/elena-georgieva/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/geoellena',
        },
      ],
    },
    title: 'On Data: Sky above, sand below, peace within',
    description:
      'An exciting technology time travel with bites from reality, arts, and music. Hear from a data veteran how data architecture, data science, and data culture evolved over time to reach 2021 with the latest paradigms of Data Lake House and Data Democratisation.',
    time: '19:00 - 20:00',
    track: TRACKS.VISIONER,
  },
  {
    speaker: {
      name: 'Dmitry Vinnik',
      avatar: dmitryVinnik,
      position: 'Open Source Developer Advocate @Facebook',
      bio: 'Dmitry is an Open Source Developer Advocate at Facebook, where he focuses on projects in the mobile space. His goal is to help developers be more productive through open source. Dmitry continues being a developer-first, so he applies the same mindset and passion when working on open source projects, creating new educational content, or giving conference talks. Dmitry has a background in medicine and bioinformatics, but these days he is more interested in understanding developer wellness and work-life balance.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/DmitryVinnik',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://dvinnik.dev/',
        },
      ],
    },
    title: 'Impostor Syndrome: How to Overcome Your Inner Roadblock',
    description: `Do you ever wonder if you are good enough? If so, then you probably suffer from an Impostor Syndrome.
In this talk, we discuss how to identify and to overcome these self-imposed roadblocks to allow you to appreciate yourself and your voice.`,
    time: '19:00 - 20:00',
    track: TRACKS.ADVENTURER,
  },
]

export const WORKSHOPS = [
  {
    speaker: {
      name: 'Majid Hajian',
      avatar: majidHajian,
      position: 'Flutter GDE',
      bio: 'Majid is a Google developer expert, an award-winning book author, Flutter, PWA, perf enthusiast, and a passionate software developer with years of developing and architecting complex web and mobile applications.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/mhadaily',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/mhadaily',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://linkedin.com/in/mhadaily/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://majidhajian.com',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://www.youtube.com/c/mhadaily',
        },
      ],
    },
    title: 'Full Stack App with Flutter and Firebase Workshop',
    description: `Do you really want to create the most beautiful mobile application that works on both Android and iOS, Web, Windows, Linux, macOS while it's sustainable and maintainable in one single code base? you need a quick guide to get started for creating a production-ready app? Then this workshop is for you!`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#full-stack-all-with-flutter`,
    requirements: 'You are familiar at least with one programming language.',
  },
  {
    speaker: {
      name: 'Bogomil Shopov',
      avatar: bogomilShopov,
      position: 'Agile and Product Security Enthusiast',
      bio: `Bogo cares about privacy, ethical design, and freedom in many aspects.
He spends 20+ years working as a web developer and architect, analyst, manager, product owner/manager in different environments, several countries, and multiple software industries like Healthcare and Hospitality.
He wears many hats and uses the knowledge gathered to optimize the flow of value across complex systems.
He contributes to Free software and Open-source projects and initiatives like Mozilla and Creative Commons.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/bogomep',
        },
      ],
    },
    title: 'Agile Visual Threat Modeling (Against a Beer Tap Infrastructure)',
    description: `Doing threat modeling is one of the main requirements for any Agile organization. Most of the teams are doing it wrong, and as an award, they receive a false sense of security. In this workshop, I'll teach you how to do it properly in a fun way.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#agile-visual-threat-modeling`,
    requirements:
      "The workshop is suitable for everyone who works in the software development process. You'll need a working machine with access to the internet.",
  },
  {
    speaker: {
      name: 'Georgi Parlakov',
      avatar: georgiParlakov,
      bio: 'Angular and DotNet dev. RxJs 🔭 explorer. Author ✍ indepth.dev, medium.com, dev.to. Testing 👍 proponent. A dad ❤. More about his projects at https://gparlakov.github.io/',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/gparlakov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/gparlakov',
        },
      ],
    },
    title: 'Angular performance',
    description: `Optimizing Angular is an ongoing effort.
This workshop will let you get your hands dirty and see the improved performance. And show the performance tools and leverage points that are available to every Angular dev.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#angular-performance`,
    requirements:
      'People who have a background and experience with Angular will benefit the most from this workshop.',
  },

  {
    speakers: [
      {
        name: 'Anton Sankov ',
        avatar: antonSankov,
        position: 'MTS @VMware Carbon Black',
        bio: 'Anton is a software engineer, currently employed by VMware Carbon Black, working with Go and Kubernetes. For the past year his focus has been mostly on Security and building easy-to-use security products.',
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.TWITTER,
            url: 'https://twitter.com/a_sankov',
          },
          {
            icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
            url: 'https://www.linkedin.com/in/anton-sankov-595510123/',
          },
        ],
      },
      {
        name: 'Boris Stoyanov',
        avatar: borisStoyanov,
        position: 'Software engineer @Coursera',
        bio: "Boris is working with Go in Coursera, helping bring hands-on education to everyone. He's focusing on observability and processwork, building a system that's robust and maintainable.",
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
            url: ' https://www.linkedin.com/in/borisstoyanov/',
          },
        ],
      },
    ],
    title: 'Go 101',
    description: `Are you wondering why some language named Go is getting more and more popular? If yes, join this workshop to understand why and make your first steps with it. We will start from scratch and by the end of the workshop you will know how to write Go programs the Go way.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#go-101`,
    requirements:
      "There aren't any requirements except for a positive attitude, open mind, and desire to learn.",
  },
  {
    speaker: {
      name: 'Daniel Rankov',
      avatar: danielRankov,
      position: 'Cloud & DevOps Manager',
      bio: 'Daniel Rankov leads the Cloud Center of Excellence (CCOE) team and guides the overall Cloud and DevOps strategy at MentorMate. Daniel is in a player-coach role, and he has multiple certifications on AWS. Daniel is co-organizer of the AWS Bulgaria User Group, and in 2021 he is recognized as the first AWS Community Hero in Bulgaria.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/DanielRankov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/danielrankov/',
        },
      ],
    },
    title: 'Build a Serverless Web Application with AWS',
    description: `Build your first serverless web application on AWS! We deploy static content, dynamic content and authentication to achieve hyper scaling web application.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#build-serverless-web-application`,
    requirements: `To complete this tutorial, you will need:
- An AWS account, created at least 24h before the workshop
- A text editor
- Recommended browser: The latest version of Chrome Basic familiarity with AWS and services like AWS Lambda, Amazon API Gateway, Amazon DynamoDB is recommended but not required.
- Bring your own AWS account for the event. The account should be created at least 24h before the event start. Estimated cost: 0.25$ `,
  },
  {
    speaker: {
      name: 'Danail Hadjiatanasov',
      avatar: danailHadjiatanasov,
      position: 'Software Engineer at Material-UI',
      bio: 'With 8+ years of experience in the field, 4 of which spent on building a UI library used by some of the largest banks in the world. Today he is proud to be part of Material-UI core team and continue doing what he loves - develop high quality, reusable UI components.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/danail_h',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: 'https://github.com/DanailH',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/danail-hadjiatanasov/',
        },
      ],
    },
    title: 'State management for reusable React components',
    description: `If you ever had a case of managing state in reusable components you probably wondered what would be the best way to handle that. We will check some common examples of UI components that require an internal state and we will explore possible ways to achieve that and in the same way expose an API for developers to control or retrieve your component state. After nailing the basics we will create a React component using the described approach from scratch and aim to cover requirements such as high reusability, available API, and extendability.`,
    time: '10:30 - 12:00',
    detailsUrl: `${BLOG_PATH}#state-management-for-reusable-react-components`,
    requirements: 'Advanced React knowledge is a must for this workshop.',
  },
  {
    speaker: {
      name: 'Hristina Biserinska',
      avatar: hristinaBiserinska,
      position: 'Data Scientist @Melon',
      bio: 'Originally from Petrich, Hristina has lived from suburban college towns in Bulgaria, the capital Sofia, to university centers in the Netherlands, all throughout, developing her passion for that data science which meaningfully improves our reality through data. She continually seeks to apply the potential that machine learning holds for making good not only for the business, but for the society as well.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/biserinska/',
        },
      ],
    },
    title:
      'Ensure clean, potable water to communities in Tanzania through Machine Learning',
    description: `The access to clean drinking water is still one of the most significant problems for millions of people. For some areas in the world, water points were created to ensure the access to clean water. A smart understanding of which water points will fail can improve maintenance operations and ensure that clean, potable water is available to communities across Tanzania. Our goal in this workshop will be to predict which water pumps are functional, which need repair, and which don't work at all, experimenting with different classification algorithms.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#ensuare-clean-water-in-tanzania-through-ml`,
    requirements: (
      <>
        - Knowledge of Python basics and experience with pandas, matplotlib and
        scikit-learn libraries.
        <br />- Installed{' '}
        <a
          href="https://www.anaconda.com/products/individual"
          rel="noopener noreferrer"
          target="_blank"
        >
          Anaconda 3 (Individual Edition)
        </a>
        .
      </>
    ),
  },
  {
    speaker: {
      name: 'Preslav Mihaylov ',
      avatar: preslavMihaylov,
      position: (
        <>
          Software Engineer @Uber
          <br />
          Speaker & Technical Trainer
        </>
      ),
      bio: `Preslav is a software engineer at Uber, working on Java & Go microservices in the FinTech domain with a solid background in public speaking & technical training.
He's been leading various programming courses, seminars & workshops since 2015 to more than 2000+ attendees from 10+ countries.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/PreslavMihaylov',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/preslavmihaylov/',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://pmihaylov.com',
        },
      ],
    },
    title: 'Concurrency Fundamentals in Practice with Java',
    description: `Concurrency is a topic often overlooked when studying programming. Why study it? You'll just avoid spawning threads and you'd be okay, right?
The bad news is - you're already writing concurrent code. In this workshop, we'll cover the so much needed fundamentals with lots of practical examples.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#concurrency-fundamentals-java`,
    requirements:
      "A basic understanding of Java's syntax is required - what's a class, interface, control-flow structures, etc. If you don't have experience with Java, but you're coming from a language with similar taste, such as C#, you should be fine. However, I'd still recommend getting your hands dirty with Java before the workshop by doing some fizzbuzz problems at e.g. leetcode so that we don't spend time covering Java's syntax.",
  },
  {
    speaker: {
      name: 'Subby Angelov',
      avatar: subbyAngelov,
      position: 'Team Lead @Nemetschek Bulgaria',
      bio: 'Some 20 years ago, Subby started as a developer. Now he leads 9 teams with more than 80 people. In his leadership practice, he had to solve many different cases involving many different people. The experience he gained is worth sharing. Subby believes that the best way you can do good to someone is by sharing what you know. He believes in shortcuts and speed lines. He is a passionate speaker and enthusiastic to help people achieve more.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/subbyangelov/',
        },
      ],
    },
    title: 'Advanced Leadership class',
    description: `In this workshop, through real cases we will find answers of:
- How to get most of you and your team together?
- How to find time for the important things?
- Which are those things?
- How to manage the upper management and/or customers?
… those and many more …
We will discuss real case scenarios and find out what works and for whom? What are the limitations and the benefits of the different approaches? You will go with practical ideas on how to tackle the challenges on your very next working day.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#advanced-leadership-class`,
    requirements:
      "There aren't any requirements. If you want to upskill yourself and gain practical leadership skills, this class is for you.",
  },
  {
    speaker: {
      name: 'Ivan Iushkevich ',
      avatar: ivanIushkevich,
      position: 'DINS, Application Security Engineer',
      bio: 'Penetration tester and security auditor with 10+ years of experience. I am passionate about searching bugs, vulnerabilities in the source code of different applications, have vast experience in banking systems and web application penetration testing. Also a security trainer and lecturer in a university.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: ' https://twitter.com/w34kp455',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GITHUB,
          url: ' https://github.com/zzzteph',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/ivan-yushkevich-4ba79542',
        },
      ],
    },
    title: 'AppSec405',
    description: `Bob recently joined a big and very famous company, Appsec404, which conducts security assessments. Bob has always dreamed of working in this area, and now he has a chance, and he does not want to miss it. At the same time, he was not the only one hired and got the coveted position of an application security specialist, and Bob must prove himself as good as possible. Bob will have to solve many problems related to finding and fixing vulnerabilities to move up the career ladder. At least, the main thing is to do the job and not follow any sorts of rabbits, right? During our workshop, you will help Bob and face many tasks related to finding vulnerabilities in various web applications and fixing them. To not go into details, we will study the vulnerabilities and reports published on HackerOne and Bugcrowd, and solve a few real problems. In addition, you will learn what needs attention when testing and implementing various functions in web applications and what can happen if certain functions are not used promptly.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#appsec-405`,
    requirements: `
        The workshop is 95% practical, so attendees must have any laptop/PC and
        internet. No additional software is required.

        Who is this workshop for?
        Web developers, appsec specialists, and anyone interested in information
        security.
`,
  },
  {
    speakers: [
      {
        name: 'Georgi Dimitrov',
        avatar: georgiDimitrov,
        position: 'Head of Development @Iris.AI',
        bio: 'Georgi finishes his bachelors degree in Software Engineering and his masters in Technological Entrepreneurship.  He is an ex math, teakwondo and chess competitor.  Georgi starts working in IT at the times when there is still no cloud. After several years he starts putting more and more efforts in the product side of the software development and around 3 years ago decides that he wants to embrace a dynamic impactfull journey with Iris.AI and leverage AI and NLP for scientific exploration.',
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
            url: 'https://www.linkedin.com/in/georgi-d-dimitrov/',
          },
        ],
      },
      {
        name: 'Victor Botev',
        avatar: victorBotev,
        position: 'CTO @Iris.AI',
        bio: 'Victor takes his bachelors degree in Software Engineering and his masters in Artificial Intellignce in Sofia and Computer Systems and Networks in Sweden. With rich experience both in academia and industry, in his career, Victor has developed AI for detecting fraud in using the electrical grid and also helping cars drive autonomously. He has also participated in numerous challenging projects, amongst them building a whole formula car with which he won various international awards.',
        socialChannels: [
          {
            icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
            url: 'https://www.linkedin.com/in/victorbotev/',
          },
        ],
      },
    ],
    title:
      'Leverage AI and NLP to solve a huge scientific problem. Win a prize in the process!',
    description: `Come to our workshop and experience first hand what it is like to use AI for state of the art science exploration and solving future hi-tech challenges. We will host a small competition where every participant will use our NLP engine to help the humanity solve a major scientific problem! There will be prices for the best :)  Before starting the competition you will witness a demo of the engine and the technology behind it.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#leverage-ai-nlp`,
    requirements: `The workshop is suitable for everyone that loves or is interested in AI, NLP, Science, or just wants to compete for winning a prize while helping to solve challenging problems.

No specific skills are required for participation, only motivation, and curiosity!`,
  },
  {
    speaker: {
      name: 'David Leitner',
      avatar: davidLeitner,
      position: 'Coding Architect @SQUER',
      bio: 'David is Coding Architect at SQUER, a Viennese Software Company, which is working with different stacks and environments, but always an overarching mission: connect ideas and provide impact — with technology. He spends much of his time on the frontlines tackling the challenges of scaling software and complex domains, with a strong focus on reactive systems, cloud-native architectures and state of the art web technologies. David enjoys sharing his knowledge as a speaker at conferences and as a lecturer for his post-diploma courses at the University of Applied Sciences Technikum Vienna.',
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/duffleit',
        },
      ],
    },
    title: 'Reactive Architectures',
    description: `Modern software architectures are amidst a paradigm shift — more and more software landscapes are built upon asynchronous and reactive patterns. This movement is understandable, as it takes scalability, resilience, and real-time integration to an elusive new level. Unfortunately, we observe that many new customer projects only use parts of this paradigm shift sensibly. Thus, they often end up with an only partially reactive architecture that introduces a lot of new complexity without allowing us to utilize the full potential of reactivity.
This workshop will teach you the reactive paradigm's central concepts, core ideas, and the corresponding manifest and make them understandable. Based on this knowledge, we will go into hands-on use-cases to build an end-2-end reactive system, from a reactive frontend integrated via GraphQL, back to a persistent event stream, that allows us to follow a fully push-based design. We will understand concrete patterns, like event-centric persistence, the concept of hydrations or projections, and how improve resilience and elasticity by such a software architecture.
`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#reactive-architectures`,
  },
  {
    speaker: {
      name: 'Marian Marinov',
      avatar: marianMarinov,
      position: 'Head of DevOps @WebHostingCanada',
      bio: "Marian is a system administrator by heart. He is working with Linux for almost 20 years. Currently he is Head of DevOps at WebHostingCanada. He is a big fan of FOSS and regularly speaks at different FOSS conferences around the world. Marian also helps with the organization of OpenFest - Bulgaria's biggest FOSS conferences. In his spare time he teaches Linux system administration and Network security courses in Sofia University and SoftUni.",
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.LINKEDIN,
          url: 'https://www.linkedin.com/in/mariandmarinov/',
        },
      ],
    },
    title: 'LXD workshop',
    description: `Everyone needs a dev/testing environment for their project, why don't we create it under our own Linux OS with one of the best open source container tool available? This workshop will give you the basics needed to manage containers using LXD.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#lxd-workshop`,
    requirements: `Previous experience with the Terminal is a must.`,
  },
  {
    speaker: {
      name: 'Maciej Treder',
      avatar: maciejTreder,
      position: 'Senior Software Development Engineer @Akamai Technologies',
      bio: `Senior Software Development Engineer at Akamai Technologies; Twilio Champion;
Enthusiast of web technologies especially Single Page Apps, Progressive Web Apps, microservices, and Internet of Things. Author of articles about JavaScript, NodeJS, and Angular. Open Source contributor - creator of @ng-toolkit opensource project - set of tools for expanding existing Angular applications.`,
      socialChannels: [
        {
          icon: SOCIAL_MEDIA_ICONS.TWITTER,
          url: 'https://twitter.com/maciejtreder',
        },
        {
          icon: SOCIAL_MEDIA_ICONS.GENERIC,
          url: 'https://www.maciejtreder.com/',
        },
      ],
    },
    title:
      'Angular Universal - be more SEO/CDN/User friendly with server-side rendering',
    description: `Angular Universal is an underestimated server-side-rendering technique. During this workshop you will learn that SSR is not only about the Search Engine Optimization (SEO) but also improves application performance, provides better user experience and enables you to create more sophisticated Single Page Applications.`,
    time: '10:30 - 13:30',
    detailsUrl: `${BLOG_PATH}#angular-universal`,
    requirements: (
      <>
        The workshop is suitable for everyone who has an idea of what SPA and
        Angular are. However, the best audience is entry and mid-level
        developers.
        <br />
        <br />
        <a
          href="https://github.com/maciejtreder/angular-uvniersal-training"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://github.com/maciejtreder/angular-uvniersal-training
        </a>
      </>
    ),
  },
]
