import React from 'react'

import hacksoftLogo from './assets/hacksoft.png'
import hackbulgariaLogo from './assets/hackbulgaria.png'

import * as styles from './styles.module.scss'

const OrganizingPartners = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.partner}>
        Organizing partner
        <a
          href="https://hacksoft.io/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={hacksoftLogo} alt="hacksoft logo" />
        </a>
      </div>

      <div className={styles.partner}>
        The conference is organized by
        <a
          href="https://hackbulgaria.com/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={hackbulgariaLogo} alt="hackbulgaria logo" />
        </a>
      </div>
    </div>
  </div>
)

export default OrganizingPartners
