export const NAV_ITEMS = [
  { content: 'About', to: 'about' },
  /* { content: 'Schedule', to: 'schedule' }, */
  /* { content: 'Speakers', to: 'talks' }, */
  { content: 'Sponsors', to: 'sponsors' },
  { content: 'Newsletter', to: 'newsletter' },
  // { content: 'Partners', to: 'partners' },
  /* { content: "Tickets", to: "tickets" }, */
  { content: 'Past events', to: 'past-events' },
  // { content: 'Join the Crew', to: 'join-the-crew' },
  { content: 'News', to: 'news' },
  /* {
   *   content: 'F.A.Q.',
   *   link: '/news/hackconf-2021-online-frequently-asked-questions/',
   * }, */
]
