// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--1CM-_";
export var container = "styles-module--container--1TPlN";
export var sectionTitle = "styles-module--sectionTitle--2MPT7";
export var newsItem = "styles-module--newsItem--3toMC";
export var left = "styles-module--left--qUSNl";
export var right = "styles-module--right--RSLvH";
export var creationDate = "styles-module--creationDate--1ugix";
export var title = "styles-module--title--3zBGa";
export var description = "styles-module--description--3nam1";
export var hashtag = "styles-module--hashtag--2fTlk";