import React from 'react'

import cx from 'classnames'

import * as styles from './styles.module.scss'

class Newsletter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.wrapper} id="newsletter">
          <div className={cx('container', styles.container)}>
            <div className={cx('section-title', styles.title)}>
              Subscribe for updates
            </div>
            <div
              className="ml-form-embed"
              data-account="791671:v6b7v5g0j4"
              data-form="876532:e3b0y9"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Newsletter
